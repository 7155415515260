import React from "react";

const BarCode=()=>{
    return (
        <>
        <h3>BarCode</h3>
        </>
    )
}

export default BarCode;